<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode }" class="c-app" style="background-color: black">

    <div class="overflow-auto min-vh-100 w-100" style="position: absolute; margin: 45px auto;">
      <div class="overflow-auto m-auto justify-content-center  align-items-center min-vh-100" >
        <img src="@/assets/bg.jpg">
      </div>
    </div>
    <CContainer >
      <CRow class="justify-content-center  align-items-center min-vh-100">
        <CCol md="8">
          <div class="text-center">
            <img src="@/assets/logo.png" width="30%"/>
            <p class="pt-3  font-xl text-white suk">Originalize your masterpiece</p>
            <!--            <h5> extraordinary NFTs on the world's  & largest NFT marketplace.</h5>-->
          </div>
          <div class="text-center">
            <CButton @click="onCollector"  class="pl-4 pr-4 mr-2 mt-2 text-white suk" shape="pill" color="success" variant="outline">Collector</CButton>
            <CButton @click="onArtist"  class="pl-4 pr-4 mr-2 mt-2 text-white suk" shape="pill" color="info" variant="outline">Creator</CButton>
          </div>

          <p class="pt-3 pl-3 mt-5 font-xl text-white suk text-center">NFT Asset ● Copyright ● Artwork</p>
        </CCol>
      </CRow>

    </CContainer>
  </div>
</template>

<script>
export default {
  name: 'Splash',
  created() {
    // setTimeout(() => this.$router.push('/home'), 5000);
  },
  methods: {
    onCollector(){
      this.$router.push({name:'Markets'})
    },

    onArtist(){
      this.$router.push({name:'list'})
    }
  },
}

</script>
<style >
/*body {*/

/*  !*background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);*!*/
/*  background-image: url("../../assets/bg.jpg");*/
/*  background-repeat:no-repeat;*/
/*  !*overflow: hidden;*!*/
/*  !*display: flex;*!*/
/*  !*font-family: "Anton", sans-serif;*!*/
/*  !*justify-content: center;*!*/
/*  !*align-items: center;*!*/
/*}*/
@font-face {
  font-family: myFirstFont;
  src: url(../../assets/font/SukhumvitSet.ttf);
}
.suk{
  font-family: myFirstFont;
}
.night {
  position: relative;
  width: 100%;
  height: 100%;
  transform: rotateZ(45deg);
}

.shooting_star {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 2px;
  background: linear-gradient(-45deg, #5f91ff, rgba(0, 0, 255, 0));
  border-radius: 999px;
  filter: drop-shadow(0 0 6px #699bff);
  -webkit-animation: tail 3000ms ease-in-out infinite, shooting 3000ms ease-in-out infinite;
  animation: tail 3000ms ease-in-out infinite, shooting 3000ms ease-in-out infinite;
}
.shooting_star::before {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  right: 0;
  height: 2px;
  background: linear-gradient(-45deg, rgba(0, 0, 255, 0), #5f91ff, rgba(0, 0, 255, 0));
  transform: translateX(50%) rotateZ(45deg);
  border-radius: 100%;
  -webkit-animation: shining 3000ms ease-in-out infinite;
  animation: shining 3000ms ease-in-out infinite;
}
.shooting_star::after {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  right: 0;
  height: 2px;
  background: linear-gradient(-45deg, rgba(0, 0, 255, 0), #5f91ff, rgba(0, 0, 255, 0));
  transform: translateX(50%) rotateZ(45deg);
  border-radius: 100%;
  -webkit-animation: shining 3000ms ease-in-out infinite;
  animation: shining 3000ms ease-in-out infinite;
  transform: translateX(50%) rotateZ(-45deg);
}
.shooting_star:nth-child(1) {
  top: calc(50% - 7px);
  left: calc(50% - 139px);
  -webkit-animation-delay: 337ms;
  animation-delay: 337ms;
}
.shooting_star:nth-child(1)::before, .shooting_star:nth-child(1)::after {
  -webkit-animation-delay: 337ms;
  animation-delay: 337ms;
}
.shooting_star:nth-child(2) {
  top: calc(50% - 136px);
  left: calc(50% - 179px);
  -webkit-animation-delay: 2010ms;
  animation-delay: 2010ms;
}
.shooting_star:nth-child(2)::before, .shooting_star:nth-child(2)::after {
  -webkit-animation-delay: 2010ms;
  animation-delay: 2010ms;
}
.shooting_star:nth-child(3) {
  top: calc(50% - -88px);
  left: calc(50% - 272px);
  -webkit-animation-delay: 1241ms;
  animation-delay: 1241ms;
}
.shooting_star:nth-child(3)::before, .shooting_star:nth-child(3)::after {
  -webkit-animation-delay: 1241ms;
  animation-delay: 1241ms;
}
.shooting_star:nth-child(4) {
  top: calc(50% - 168px);
  left: calc(50% - 283px);
  -webkit-animation-delay: 1045ms;
  animation-delay: 1045ms;
}
.shooting_star:nth-child(4)::before, .shooting_star:nth-child(4)::after {
  -webkit-animation-delay: 1045ms;
  animation-delay: 1045ms;
}
.shooting_star:nth-child(5) {
  top: calc(50% - 22px);
  left: calc(50% - 81px);
  -webkit-animation-delay: 8628ms;
  animation-delay: 8628ms;
}
.shooting_star:nth-child(5)::before, .shooting_star:nth-child(5)::after {
  -webkit-animation-delay: 8628ms;
  animation-delay: 8628ms;
}
.shooting_star:nth-child(6) {
  top: calc(50% - -134px);
  left: calc(50% - 125px);
  -webkit-animation-delay: 1705ms;
  animation-delay: 1705ms;
}
.shooting_star:nth-child(6)::before, .shooting_star:nth-child(6)::after {
  -webkit-animation-delay: 1705ms;
  animation-delay: 1705ms;
}
.shooting_star:nth-child(7) {
  top: calc(50% - 133px);
  left: calc(50% - 268px);
  -webkit-animation-delay: 752ms;
  animation-delay: 752ms;
}
.shooting_star:nth-child(7)::before, .shooting_star:nth-child(7)::after {
  -webkit-animation-delay: 752ms;
  animation-delay: 752ms;
}
.shooting_star:nth-child(8) {
  top: calc(50% - -198px);
  left: calc(50% - 207px);
  -webkit-animation-delay: 2227ms;
  animation-delay: 2227ms;
}
.shooting_star:nth-child(8)::before, .shooting_star:nth-child(8)::after {
  -webkit-animation-delay: 2227ms;
  animation-delay: 2227ms;
}
.shooting_star:nth-child(9) {
  top: calc(50% - -10px);
  left: calc(50% - 171px);
  -webkit-animation-delay: 9997ms;
  animation-delay: 9997ms;
}
.shooting_star:nth-child(9)::before, .shooting_star:nth-child(9)::after {
  -webkit-animation-delay: 9997ms;
  animation-delay: 9997ms;
}
.shooting_star:nth-child(10) {
  top: calc(50% - -107px);
  left: calc(50% - 63px);
  -webkit-animation-delay: 3908ms;
  animation-delay: 3908ms;
}
.shooting_star:nth-child(10)::before, .shooting_star:nth-child(10)::after {
  -webkit-animation-delay: 3908ms;
  animation-delay: 3908ms;
}
.shooting_star:nth-child(11) {
  top: calc(50% - -140px);
  left: calc(50% - 136px);
  -webkit-animation-delay: 3852ms;
  animation-delay: 3852ms;
}
.shooting_star:nth-child(11)::before, .shooting_star:nth-child(11)::after {
  -webkit-animation-delay: 3852ms;
  animation-delay: 3852ms;
}
.shooting_star:nth-child(12) {
  top: calc(50% - -129px);
  left: calc(50% - 74px);
  -webkit-animation-delay: 5395ms;
  animation-delay: 5395ms;
}
.shooting_star:nth-child(12)::before, .shooting_star:nth-child(12)::after {
  -webkit-animation-delay: 5395ms;
  animation-delay: 5395ms;
}
.shooting_star:nth-child(13) {
  top: calc(50% - 39px);
  left: calc(50% - 262px);
  -webkit-animation-delay: 7387ms;
  animation-delay: 7387ms;
}
.shooting_star:nth-child(13)::before, .shooting_star:nth-child(13)::after {
  -webkit-animation-delay: 7387ms;
  animation-delay: 7387ms;
}
.shooting_star:nth-child(14) {
  top: calc(50% - -185px);
  left: calc(50% - 12px);
  -webkit-animation-delay: 8852ms;
  animation-delay: 8852ms;
}
.shooting_star:nth-child(14)::before, .shooting_star:nth-child(14)::after {
  -webkit-animation-delay: 8852ms;
  animation-delay: 8852ms;
}
.shooting_star:nth-child(15) {
  top: calc(50% - 50px);
  left: calc(50% - 37px);
  -webkit-animation-delay: 844ms;
  animation-delay: 844ms;
}
.shooting_star:nth-child(15)::before, .shooting_star:nth-child(15)::after {
  -webkit-animation-delay: 844ms;
  animation-delay: 844ms;
}
.shooting_star:nth-child(16) {
  top: calc(50% - -93px);
  left: calc(50% - 260px);
  -webkit-animation-delay: 4878ms;
  animation-delay: 4878ms;
}
.shooting_star:nth-child(16)::before, .shooting_star:nth-child(16)::after {
  -webkit-animation-delay: 4878ms;
  animation-delay: 4878ms;
}
.shooting_star:nth-child(17) {
  top: calc(50% - 145px);
  left: calc(50% - 251px);
  -webkit-animation-delay: 8182ms;
  animation-delay: 8182ms;
}
.shooting_star:nth-child(17)::before, .shooting_star:nth-child(17)::after {
  -webkit-animation-delay: 8182ms;
  animation-delay: 8182ms;
}
.shooting_star:nth-child(18) {
  top: calc(50% - -19px);
  left: calc(50% - 229px);
  -webkit-animation-delay: 7529ms;
  animation-delay: 7529ms;
}
.shooting_star:nth-child(18)::before, .shooting_star:nth-child(18)::after {
  -webkit-animation-delay: 7529ms;
  animation-delay: 7529ms;
}
.shooting_star:nth-child(19) {
  top: calc(50% - -151px);
  left: calc(50% - 56px);
  -webkit-animation-delay: 4771ms;
  animation-delay: 4771ms;
}
.shooting_star:nth-child(19)::before, .shooting_star:nth-child(19)::after {
  -webkit-animation-delay: 4771ms;
  animation-delay: 4771ms;
}
.shooting_star:nth-child(20) {
  top: calc(50% - -161px);
  left: calc(50% - 259px);
  -webkit-animation-delay: 8083ms;
  animation-delay: 8083ms;
}
.shooting_star:nth-child(20)::before, .shooting_star:nth-child(20)::after {
  -webkit-animation-delay: 8083ms;
  animation-delay: 8083ms;
}

@-webkit-keyframes tail {
  0% {
    width: 0;
  }
  30% {
    width: 100px;
  }
  100% {
    width: 0;
  }
}

@keyframes tail {
  0% {
    width: 0;
  }
  30% {
    width: 100px;
  }
  100% {
    width: 0;
  }
}
@-webkit-keyframes shining {
  0% {
    width: 0;
  }
  50% {
    width: 30px;
  }
  100% {
    width: 0;
  }
}
@keyframes shining {
  0% {
    width: 0;
  }
  50% {
    width: 30px;
  }
  100% {
    width: 0;
  }
}
@-webkit-keyframes shooting {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(300px);
  }
}
@keyframes shooting {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(300px);
  }
}
@-webkit-keyframes sky {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}
@keyframes sky {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}
</style>
